import { createStore } from 'vuex';

export default createStore({
  state() {
    return {
      numbers: [],
      currentInput: '', // Added state property for current input
    };
  },
  getters: {
    numbers: state => state.numbers,
    currentInput: state => state.currentInput, // Added getter for current input
  },
  mutations: {
    ADD_NUMBER(state, number) {
      state.numbers.push(number);
    },
    DELETE_LAST_NUMBER(state) {
      state.numbers.pop();
    },
    UPDATE_INPUT(state, value) { // Define the mutation
      state.currentInput = value;
    },
  },
  actions: {
    addNumber({ commit }, number) {
      commit('ADD_NUMBER', number);
    },
    deleteLastNumber({ commit }) {
      commit('DELETE_LAST_NUMBER');
    },
    updateInput({ commit }, value) { // Define the action
      commit('UPDATE_INPUT', value);
    },
  },
});
