<template>
  <div class="number-list-component mt-4">
    <div class="flex flex-col sm:flex-row items-center">
      <div class="list-of-numbers bg-white border border-black rounded">
        <div v-if="numbers.length === 0" class="last-spins-placeholder">
          Numbers will appear here
        </div>
        <ul v-else class="number-list">
          <li v-for="(number, index) in numbers.slice().reverse()" :key="index" :class="numberColor(number)">
            {{ number }}
          </li>
        </ul>
      </div>
      <div class="number-inputs">
        <button @click="deleteLastNumber" class="delete-button">Delete</button>
        <input
          type="number"
          v-model="currentInput"
          @keyup.enter="addNumberAction"
          placeholder="Enter Numbers"
          class="input-field"
          :class="{ 'shake-once': animateInput }"
        >
        <button @click="addNumberAction" class="submit-button">Add</button>
      </div>
    </div>
  </div>
</template>


<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';

export default {
  name: 'NumberList',
  data() {
    return {
      animateInput: false,
    };
  },
  mounted() {
    this.animateInput = true;
  },
  computed: {
    ...mapGetters(['numbers']),
    currentInput: {
      get() {
        return this.$store.state.currentInput;
      },
      set(value) {
        this.$store.commit('UPDATE_INPUT', value);
      }
    }
  },
  methods: {
    ...mapActions(['addNumber', 'deleteLastNumber']),
    addNumberAction() {
      const number = parseInt(this.currentInput, 10);
      if (!isNaN(number) && number >= 0 && number <= 36) {
        this.addNumber(number);
        this.currentInput = '';
      } else {
        alert("Please enter a number between 0 and 36");
      }
    },
    numberColor(number) {
      if (number === 0) return 'bg-green-500';
      const redNumbers = [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36];
      return redNumbers.includes(number) ? 'bg-red-500' : 'bg-black';
    },
    ...mapMutations({
      setCurrentInput: 'UPDATE_INPUT'
    })
  }
};
</script>


<style>
.number-list-component {
  font-family: 'Russo One', sans-serif;
  max-width: 100vw;
  box-sizing: border-box;
  margin-top: 1rem;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.sm\:flex-row {
  @media (min-width: 640px) {
    flex-direction: row;
  }
}

.items-center {
  align-items: center;
}

.list-of-numbers {
  background-color: white;
  border: 1px solid black;
  border-radius: 0.25rem;
  flex-grow: 1;
  overflow-x: auto;
  margin-bottom: 1rem;
  margin-right: 1rem;
  max-width: 100%;
}

.number-list {
  display: flex;
  flex-direction: row;
}

.number-list li {
  white-space: nowrap;
  padding: 0.5rem;
  border-radius: 0.25rem;
  color: white;
  margin: 0.25rem;
}

.last-spins-placeholder {
  color: black;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.number-inputs {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  max-width: calc(100vw - 48px);
  box-sizing: border-box;
}

.input-field {
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  box-sizing: border-box;
}

@media (max-width: 640px) {
  .number-inputs {
    justify-content: center; /* Center the buttons and input field on mobile */
  }

  .input-field {
    max-width: 50%; /* Adjust width for mobile */
  }
}

@media (min-width: 640px) {
  .number-inputs {
    justify-content: flex-end; /* Align to the right on larger screens */
  }

  .input-field {
    max-width: 45%; /* Larger width for input field on larger screens */
  }

  .submit-button, .delete-button {
    max-width: 25%; /* Larger width for buttons on larger screens */
  }

  .number-list {
    flex-grow: 4; /* Increase size on larger screens */
  }
}

.submit-button, .delete-button {
  padding: 0.5rem 1rem;
  background-color: #0f9d0f;
  color: white;
  border-radius: 0.25rem;
  flex-shrink: 0;
  box-sizing: border-box;
}

.submit-button:hover {
  background-color: #085520;
}

.delete-button {
  background-color: #f56565;
  color: white;
  border-radius: 0.25rem;
}

.delete-button:hover {
  background-color: #c53030;
}



@keyframes shake {
  0%, 100% { transform: rotate(0deg); }
  12.5%, 37.5%, 62.5%, 87.5% { transform: rotate(5deg); }
  25%, 50%, 75% { transform: rotate(-5deg); }
}

.shake-once {
  animation: shake 2s;
}
</style>