<template>
  <div class="roulette-stats">
    <!-- First table for the first six elements -->
    <table class="first-table">
      <thead>
        <tr>
          <th>1-12</th>
          <th>13-24</th>
          <th>25-36</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td :class="{ 'number-warning': statistics['1st 12'] >= 4 }" :key="`1st12-${statistics['1st 12']}`">{{ statistics['1st 12'] || 0 }}</td>
          <td :class="{ 'number-warning': statistics['2nd 12'] >= 4 }" :key="`2nd12-${statistics['2nd 12']}`">{{ statistics['2nd 12'] || 0 }}</td>
          <td :class="{ 'number-warning': statistics['3rd 12'] >= 4 }" :key="`3rd12-${statistics['3rd 12']}`">{{ statistics['3rd 12'] || 0 }}</td>
        </tr>
        <tr>
          <th>1-4-7..</th>
          <th>2-5-8..</th>
          <th>3-6-9..</th>
        </tr>
        <tr>
          <td :class="{ 'number-warning': statistics['1st Row'] >= 4 }" :key="`1stRow-${statistics['1st Row']}`">{{ statistics['1st Row'] || 0 }}</td>
          <td :class="{ 'number-warning': statistics['2nd Row'] >= 4 }" :key="`2ndRow-${statistics['2nd Row']}`">{{ statistics['2nd Row'] || 0 }}</td>
          <td :class="{ 'number-warning': statistics['3rd Row'] >= 4 }" :key="`3rdRow-${statistics['3rd Row']}`">{{ statistics['3rd Row'] || 0 }}</td>
        </tr>
        <tr>
          <th>Voisins</th>
          <th>Orphelins</th>
          <th>Tier</th>
        </tr>
        <tr>
          <td :class="{ 'number-warning': statistics['Voisins'] >= 4 }" :key="`Voisins-${statistics['Voisins']}`">{{ statistics['Voisins'] || 0 }}</td>
          <td :class="{ 'number-warning': statistics['Orphelins'] >= 4 }" :key="`Orphelins-${statistics['Orphelins']}`">{{ statistics['Orphelins'] || 0 }}</td>
          <td :class="{ 'number-warning': statistics['Tier'] >= 4 }" :key="`Tier-${statistics['Tier']}`">{{ statistics['Tier'] || 0 }}</td>
        </tr>
      </tbody>
    </table>

    <!-- Second table for the last six elements -->
    <table class="second-table">
      <tbody>
        <tr>
          <th>Red</th>
          <th>Black</th>
        </tr>
        <tr>
          <td :class="{ 'number-warning': statistics['Red'] >= 4 }" :key="`Red-${statistics['Red']}`">{{ statistics['Red'] || 0 }}</td>
          <td :class="{ 'number-warning': statistics['Black'] >= 4 }" :key="`Black-${statistics['Black']}`">{{ statistics['Black'] || 0 }}</td>
        </tr>
        <tr>
          <th>Odd</th>
          <th>Even</th>
        </tr>
        <tr>
          <td :class="{ 'number-warning': statistics['Odd'] >= 4 }" :key="`Odd-${statistics['Odd']}`">{{ statistics['Odd'] || 0 }}</td>
          <td :class="{ 'number-warning': statistics['Even'] >= 4 }" :key="`Even-${statistics['Even']}`">{{ statistics['Even'] || 0 }}</td>
        </tr>
        <tr>
          <th>1-18</th>
          <th>19-36</th>
        </tr>
        <tr>
          <td :class="{ 'number-warning': statistics['1-18'] >= 4 }" :key="`1-18-${statistics['1-18']}`">{{ statistics['1-18'] || 0 }}</td>
          <td :class="{ 'number-warning': statistics['19-36'] >= 4 }" :key="`19-36-${statistics['19-36']}`">{{ statistics['19-36'] || 0 }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';

export default {
  name: 'RouletteStats',
  computed: {
    ...mapGetters(['numbers']),

    statistics() {
      // Initialize all counts to zero.
      const stats = {
        '1st 12': 0,
        '2nd 12': 0,
        '3rd 12': 0,
        '1st Row': 0,
        '2nd Row': 0,
        '3rd Row': 0,
        'Voisins': 0,
        'Orphelins': 0,
        'Tier': 0,
        'Red': 0,
        'Black': 0,
        'Odd': 0,
        'Even': 0,
        '1-18': 0,
        '19-36': 0
      };

      // Define the ranges for the Voisins, Orphelins, and Tier sections
      const voisinsRange = [22, 18, 29, 7, 28, 12, 35, 3, 26, 0, 32, 15, 19, 4, 21, 2, 25];
      const orphelinsRange = [1, 20, 14, 31, 9, 17, 34, 6, 27, 13];
      const tierRange = [33, 16, 24, 5, 10, 23, 8, 30, 11, 36];
      const redNumbers = [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36];
      const blackNumbers = [2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35];

      // Process each number in the sequence
      this.numbers.forEach(number => {
        // Dozens and rows should not increment if the last number was zero
          if (number >= 1 && number <= 12) stats['1st 12'] = 0;
          else stats['1st 12']++;

          if (number >= 13 && number <= 24) stats['2nd 12'] = 0;
          else stats['2nd 12']++;

          if (number >= 25 && number <= 36) stats['3rd 12'] = 0;
          else stats['3rd 12']++;

          if (number % 3 === 1) stats['1st Row'] = 0;
          else stats['1st Row']++;

          if (number % 3 === 2) stats['2nd Row'] = 0;
          else stats['2nd Row']++;

          if(number === 0){
            stats['3rd Row']++;
          }else if (number % 3 === 0){
            stats['3rd Row'] = 0;
          }else stats['3rd Row']++;


        // Special bets and colors
        if (voisinsRange.includes(number)) stats['Voisins'] = 0;
        else stats['Voisins']++;

        if (orphelinsRange.includes(number)) stats['Orphelins'] = 0;
        else stats['Orphelins']++;

        if (tierRange.includes(number)) stats['Tier'] = 0;
        else stats['Tier']++;

        if (redNumbers.includes(number)) stats['Red'] = 0;
        else stats['Red']++;

        if (blackNumbers.includes(number)) stats['Black'] = 0;
        else stats['Black']++;

        // For Odd and Even, skip if the number is zero
        if (number === 0) {
          stats['Odd']++;
          stats['Even']++;
        }else{
          if (number % 2 !== 0){
            stats['Odd'] = 0;
            stats['Even']++;
          }else{
            stats['Even'] = 0;
            stats['Odd']++;
          }
        }
        
        if(number===0){
          stats['1-18']++;
          stats['19-36']++;
        }else{
          if (number <= 18) stats['1-18'] = 0;
          else stats['1-18']++

          if (number >= 19) stats['19-36'] = 0;
          else stats['19-36']++;
        }


      });

      return stats;
    }
  }
};
</script>

<style scoped>
.roulette-stats {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  font-family: 'Russo One', sans-serif;
}

.roulette-stats table {
  width: calc(50% - 10px);
  max-width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  margin: 0 5px 20px;
}

.roulette-stats th,
.roulette-stats td {
  border: 3px solid #dcb43f;
  padding: 8px;
  text-align: center;
  word-break: keep-all;
  color: #ffffff;
}

.roulette-stats th {
  background-color: #5FAF5A;
}
.roulette-stats td.number-warning {
  color: red !important; /* Adding specificity and !important to ensure override */
  animation: bounce 0.5s ease;
  animation-iteration-count: 2;
}

.number-warning {
  color: red;
  animation: bounce 0.5s ease;
  animation-iteration-count: 2;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@media (max-width: 768px) {
  .roulette-stats {
    flex-direction: column;
  }

  .roulette-stats table {
    width: 100%;
  }
}

</style>
