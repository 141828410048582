<template>
  <div class="roulette-wheel">
    <div class="table">
      <div v-for="wheel in wheels" :key="wheel.label" class="wheel-container">
        <component :is="wheel.component" :label="wheel.label" class="wheel-instance"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DozensWheel from './DozensWheel.vue';
import RowsWheel from './RowsWheel.vue';
import IntersectionsWheel from './IntersectionsWheel.vue';
import SectionsWheel from './SectionsWheel.vue';

export default {
  name: 'RouletteWheel',
  components: {
    DozensWheel,
    RowsWheel,
    SectionsWheel,
    IntersectionsWheel
  },
  computed: {
    ...mapGetters(['numbers']),
    statistics() {
      const stats = {
        '1st 12': 0,
        '2nd 12': 0,
        '3rd 12': 0,
        '1st Row': 0,
        '2nd Row': 0,
        '3rd Row': 0,
        'Voisins': 0,
        'Orphelins': 0,
        'Tier': 0,
        'Red': 0,
        'Black': 0,
        'Odd': 0,
        'Even': 0,
        '1-18': 0,
        '19-36': 0
      };
      


      const voisinsRange = [22, 18, 29, 7, 28, 12, 35, 3, 26, 0, 32, 15, 19, 4, 21, 2, 25];
      const orphelinsRange = [1, 20, 14, 31, 9, 17, 34, 6, 27, 13];
      const tierRange = [33, 16, 24, 5, 10, 23, 8, 30, 11, 36];
      this.numbers.forEach(number => {
        if (number >= 1 && number <= 12) stats['1st 12']++;
        if (number >= 13 && number <= 24) stats['2nd 12']++;
        if (number >= 25 && number <= 36) stats['3rd 12']++;
        if (number % 3 === 1) stats['1st Row']++;
        if (number % 3 === 2) stats['2nd Row']++;
        if(number === 0){
            stats['3rd Row']++;
          }else if (number % 3 === 0){
            stats['3rd Row'] = 0;
          }else stats['3rd Row']++;
        if (voisinsRange.includes(number)) stats['Voisins']++;
        if (orphelinsRange.includes(number)) stats['Orphelins']++;
        if (tierRange.includes(number)) stats['Tier']++;
      });
      return stats;
    },
    
    highlightSections() {
    const highlights = {
      dozens: this.statistics['1st 12'] >= 4 || this.statistics['2nd 12'] >= 4 || this.statistics['3rd 12'] >= 4,
      rows: false, // You'll have to define the logic for rows and other sections
      sections: false,
      intersection: false,
    };
    return highlights;
  },

  },
  data() {
    return {
      wheels: [
        { label: 'Dozens', component: 'DozensWheel' },
        { label: 'Rows', component: 'RowsWheel' },
        { label: 'Sections', component: 'SectionsWheel' },
        { label: 'Intersections', component: 'IntersectionsWheel' }
      ]
    };
  }
};
</script>

<style scoped>
.roulette-wheel {
  padding: 20px;
  text-align: center;
  margin-bottom: 15px;
  font-family: 'Russo One', sans-serif; /* Apply Russo One font */
  color: white; /* Set text color to white */
}

.table {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px; /* This applies to both columns and rows */
}

.wheel-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wheel-instance {
  width: 250px;
  height: 250px;
}

@media (max-width: 767px) {
  .wheel-container {
    flex-basis: 100%;
  }
  .wheel-instance {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 768px) {
  .wheel-instance {
    width: 200px;
    height: 200px;
  }
  .table {
    grid-gap: 20px; 
    row-gap: 30px; 
  }
}
</style>