<template>
    <div class="modal-overlay" v-if="isVisible">
        <div class="modal-content">
            <!-- Content based on current index -->
            <!-- Close Button -->
            <div class="modal-header">
                <button @click="closeModal" class="close-button">Close</button>
            </div>

            <div class="modal-body">
                <div v-if="currentIndex === 0">
                    <p>This is a roulette betting strategy application. The goal here is to enhance your perspective on
                        roulette. <strong class="red-strong">This application will not sell you anything, nor does it offer
                            any guarantee of success. </strong> <br><br>
                        The logic of this application works as follows: An average player makes roulette bets based on the
                        last 3-5 numbers that have come up. This betting logic usually progresses on probabilities, such as
                        thinking that the first dozen hasn't come in three times and now it should, or a similar scenario.
                        However, casinos are also aware of this. There doesn't even need to be any physical or magnetic
                        cheating involved. Imagine you are someone who spins the roulette wheel for 8 hours a day. Since you
                        are the person adjusting the speed of the wheel and the ball, you will be trained enough to throw
                        the ball wherever you want within a week. Therefore, in this application, we will focus on the
                        series that form in the areas where the casino does not hit, and we will see where we need to bet on
                        the wheel for this series to progress.</p>
                </div>
                <div v-else-if="currentIndex === 1">
                    <p>Enter the numbers from oldest to the newest. 10 numbers will be more than enough.</p>
                    <img src="@/assets/enter.png" alt="Stake Logo" class="modal-image">
                    <p>Those number will turn this field:</p>
                    <img src="@/assets/emptyNumbers.png" alt="Stake Logo" class="modal-image">
                    <p>into this:</p>
                    <img src="@/assets/filledNumbers.png" alt="Stake Logo" class="modal-image">
                </div>
                <div v-else-if="currentIndex === 2">
                    <p>The numbers you have entered</p>
                    <img src="@/assets/filledNumbers.png" alt="Stake Logo" class="modal-image">
                    <p>Will fill these tables. These tables indicate how many spins have not occurred in the regions
                        mentioned. As you can see, the 3rd row, where 3-6-9 are located, has not come in 4 spins, and
                        therefore the relevant area in the table has turned red. If you also enter the last 10 spins from
                        your roulette table, you can see which region has not come up for how many rounds in your game. </p>
                    <img src="@/assets/table.png" alt="Stake Logo" class="modal-image">

                </div>
                <div v-else-if="currentIndex === 3">
                    <p>However, this is the most important table of the app. The other table is just a nice-to-have
                        statistics. Because this one changes the behaviour of the wheels.</p>
                    <img src="@/assets/impTable.png" alt="Stake Logo" class="modal-image">
                </div>
                <div v-else-if="currentIndex === 4">
                    <p>Now let's take a look at this example. If you enter the following numbers</p>
                    <img src="@/assets/ex11.png" alt="Stake Logo" class="modal-image">
                    <p>The table will look like this</p>
                    <img src="@/assets/ex12.png" alt="Stake Logo" class="modal-image">
                    <p>And the wheels will look like this. The intersection wheel is the wheel in the application that
                        indicates which numbers might be suitable to play on. </p>
                    <img src="@/assets/ex13.png" alt="Stake Logo" class="modal-image">
                </div>
                <div v-else-if="currentIndex === 5">
                    <p>I would place my bets like below</p><br>
                    <img src="@/assets/ex14.png" alt="Stake Logo" class="modal-image">
                    <p>Remember, 0 and 17 were not among the recommended numbers. However,
                        on the wheel, they were among many recommended consecutive numbers and I would include these numbers
                        in the bet to reduce the risk <br><br>
                        If you somehow find this website and consider giving this app a try, I recommended you to do the
                        same. </p> <br>
                    <p>Sometimes, some numbers are highlighted in all wheels. In such cases, very few numbers are
                        highlighted on the intersection wheel. In these situations, I would wait for the next rounds.
                        <strong class="red-strong">Remember, you don't have to play every round.</strong></p>
                </div>
            </div>
            <!-- ... other pages ... -->

            <!-- Navigation Buttons -->
            <div class="modal-footer">
                <div class="button-container">
                    <button @click="goToPrevPage" v-if="currentIndex > 0" class="button-style">Back</button>
                    <span class="flex-grow"></span> <!-- This will push the Next/Close button to the right -->
                    <button @click="goToNextPage" v-if="currentIndex < totalPages - 1" class="button-style">Next</button>
                    <button @click="closeModal" v-if="currentIndex === totalPages - 1" class="button-style">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
        isVisible: Boolean,
        totalPages: Number
    },
    data() {
        return {
            currentIndex: 0
        };
    },
    emits: ['close'],
    methods: {
        goToNextPage() {
            if (this.currentIndex < this.totalPages - 1) {
                this.currentIndex++;
            }
        },
        goToPrevPage() {
            if (this.currentIndex > 0) {
                this.currentIndex--;
            }
        },
        closeModal() {
            this.$emit('close');
        }
    }
};
</script>
  
<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-image {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    /* Center the image */
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 90%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    flex-direction: column;
    height: 80%;
    position: relative;
}

.modal-header {
    display: flex;
    /* Use flexbox */
    justify-content: flex-end;
    /* Align items to the right */
    padding: 10px;
    background: white;
}

.modal-footer {
    padding: 10px;
    background: white;
}

.modal-body {
    overflow-y: auto;
    padding: 20px;
    flex-grow: 1;
}

.button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    /* Space above the button container */
}

.close-button {
    background-color: red;
    color: white;
    border: none;
    padding: 10px;
    margin-left: auto;
    /* Push the button to the right */
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}



.close-button:hover {
    background-color: darkred;
    /* Optional: Darker red on hover */
}

.button-style {
    background-color: #dcb43f;
    /* Cupertino blue color */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button-style:hover {
    background-color: #957b2d;
    /* Darker blue on hover */
}

.flex-grow {
    flex-grow: 1;
}

.red-strong {
    font-weight: bold;
    color: #ec2020;
}

/* Responsive Design */
@media (min-width: 600px) {
    .modal-content {
        max-width: 50%;
    }
}</style>
  