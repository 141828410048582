<template>
  <div class="site-table">
    <table class="w-full text-white">
      <tbody>
        <!-- Logos Row -->
        <tr>
          <td class="logo-cell">
            <a href="https://500.casino/r/cekpot" target="_blank">
              <img src="@/assets/500Casino.png" alt="500 Casino" class="logo">
            </a>
          </td>
          <td class="logo-cell">
            <a href="https://stake.com/?c=cekpot" target="_blank">
              <img src="@/assets/Stake_logo.png" alt="Stake" class="logo">
            </a>
          </td>
        </tr>

        <!-- Text Row -->
        <tr>
          <td class="text-cell">
            <a href="https://500.casino/r/cekpot" target="_blank">
              <ul class="pros-list">
                <li>Rapid crypto transactions</li>
                <li>Rakeback payments.</li>
                <li>The more you play the more casino pays you back</li>
                <li>You can trade your Steam skins to wager</li>
                <li>Sports betting options you may not find elsewhere</li>
              </ul>
            </a>
          </td>
          <td class="text-cell">
            <a href="https://stake.com/?c=cekpot" target="_blank">
              <ul class="pros-list">
                <li>By far, the most famous online casino</li>
                <li>Rapid Crypto Transactions</li>
                <li>They sponsor UFC, Everton, and Drake.</li>
                <li>So many deposit or withdrawal options</li>
              </ul>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'SiteTable',
};
</script>

<style scoped>
.site-table table {
  border-collapse: collapse;
  width: 100%;
}

.site-table td {
  border: 1px solid #dcb43f;
  padding: 8px;
  text-align: center; /* Center all cell content */
  vertical-align: middle; /* Center content vertically */
}

strong {
  font-weight: bold;
  font-family: 'Russo One', sans-serif;
}

.pros-list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  text-align: center; /* Center text inside the list */
}

.pros-list li {
  padding-bottom: 5px;
}

.pros-list li:nth-child(odd) {
  color: #dcb43f;
}

.pros-list li:nth-child(even) {
  color: #e0d6b8;
}

.logo-cell {
  text-align: center;
  vertical-align: middle;
}

.text-cell {
  text-align: center;
  vertical-align: middle;
}

.logo {
  display: block;
  margin: 0 auto; /* Center the logos */
  width: 100%;
  max-width: 150px;
  height: auto;
}

@media (max-width: 640px) {
  .logo {
    max-width: 120px;
  }
}
</style>
