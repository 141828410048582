<template>
  <div id="app" class="p-5" style="background-color: #302A58;">

    <!-- Row for logo and 'Play Roulette' text -->
    <div class="flex justify-between items-center mb-4">
      <div class="flex-grow text-center">
        <img src="./assets/cek.png" alt="Logo" class="w-24 mx-auto">
      </div>
    </div>

    <!-- Text above the SiteTable component -->
    <div class="flex justify-center mb-4" style="padding-top: 20px;">
      <div class="flex items-center">
        <!-- Left emoji with bounce animation -->
        <span class="emoji-bounce">👇</span>
        
        <!-- Center text that wraps on small screens -->
        <p class="text-custom text-2xl text-center mx-4">
          Selection of trusted online casinos where you can play for real money
        </p>
        
        <!-- Right emoji with bounce animation -->
        <span class="emoji-bounce">👇</span>
      </div>
    </div>

    <!-- SiteTable component -->
    <SiteTable ref="siteTable"></SiteTable>

    <!-- Clickable Text to Reveal Roulette Statistics Tool -->
    <div class="flex justify-center mb-4" style="padding-top: 20px;">
      <button @click="toggleStatistics" class="text-custom text-2xl cursor-pointer">
        {{ showStatistics ? 'Hide' : 'Click here to reveal' }} the Roulette Statistics Tool
      </button>
    </div>

    <!-- Conditional Rendering of the Statistics Section -->
    <div v-if="showStatistics" class="flex flex-col items-center">
      <div class="w-full">
        <h5 style="text-align: center; color: #dcb43f; font-size: 20px; padding-top: 10px;">
          A Roulette statistics tool that shows how many rounds a specific section hasn't appeared for. 👇
        </h5>
        <NumberList></NumberList>
        <RouletteWheel></RouletteWheel>
        <RouletteStats></RouletteStats>
      </div>
    </div>

    <!-- Tutorial Modal -->
    <TutorialModal 
      :isVisible="isTutorialModalVisible" 
      :totalPages="6" 
      @close="isTutorialModalVisible = false"
    ></TutorialModal>
  </div>
</template>

<script>
import RouletteWheel from './components/RouletteWheel.vue';
import NumberList from './components/NumberList.vue';
import RouletteStats from './components/RouletteStats.vue';
import SiteTable from './components/SiteTable.vue';
import TutorialModal from './components/TutorialModal.vue';

export default {
  name: 'App',
  components: {
    RouletteWheel,
    NumberList,
    RouletteStats,
    SiteTable,
    TutorialModal
  },
  data() {
    return {
      isTutorialModalVisible: false, // Set to false initially
      showStatistics: false // Initially hide the statistics section
    };
  },
  methods: {
    scrollToSiteTable() {
      const siteTableComponent = this.$refs.siteTable.$el;
      siteTableComponent.scrollIntoView({ behavior: 'smooth' });
    },
    showTutorialModal() {
      this.isTutorialModalVisible = true;
    },
    toggleStatistics() {
      this.showStatistics = !this.showStatistics; // Toggle the visibility of the statistics section
    }
  }
}
</script>

<style>
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body, #app {
  background-color: #302A58;
  overflow-x: hidden;
}

.text-custom {
  color: #dcb43f;
  text-decoration: none; /* Removed underline */
}

/* Bounce animation for emojis */
.emoji-bounce {
  animation: bounce 1.5s infinite;
  font-size: 2rem;
  margin: 0 10px;
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

html {
  scroll-behavior: smooth;
}
</style>
