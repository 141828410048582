import { createApp } from 'vue';
import { createI18n } from 'vue-i18n'; // Import vue-i18n
import App from './App.vue';
import store from './store'; // Assuming the path is correct
import '@/assets/css/main.css';
import 'swiper/css/bundle';

// Define your translations
const messages = {
  en: {
    message: {
      // Add your English translations here
      app:{
        playRoulette: "Play Roulette",
        exploreTutorial: "Explore the Must-Read Tutorial"
    },
    hello: 'Hello World',
}
// ... other English translations
},
es: {
    message: {
        // Add your Spanish translations here
        app:{
            playRoulette: "Jugar a la Ruleta",
            exploreTutorial: "Explora el Tutorial Imprescindible."
      },
      hello: 'Hola Mundo',
    }
    // ... other Spanish translations
  }
};

// Create an instance of vue-i18n
const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages,
  });

const app = createApp(App);
app.use(store);
app.use(i18n); 
app.mount('#app');
